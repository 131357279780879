/* General Styling */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

h2 {
  color: #34495e;
}

p {
  font-size: 1.1rem;
}

button.cta-button {
  background-color: #29a6ff;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  border-radius: 10px;
}

button.cta-button:hover {
  background-color: #1e78d3;
  transform: scale(1.05);
}

/* Header Section */
.header {
  display: flex;
  background-color: #ffffff;
  color: white;
}

.logo {
  width: 200px;
  height: 100px;
  object-fit: cover;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.hero {
  margin-top: 1rem;
}

/* About Us Section */
.about-us {
  background-image: url('../src/images/main.jpeg');
  background-size: cover;
  background-position: center;
  padding: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-container {
  background-color: white;
  border-radius: 15px;
  padding: 2rem;
  max-width: 700px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.about-us h2, .about-us p {
  color: #34495e;
  margin: 0;
}

.about-us p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

/* Services Section */
.services {
  background-color: white;
  padding: 3rem 0;
}

.services-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}

.service {
  background-color: #ecf0f1;
  padding: 1.5rem;
  border-radius: 8px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.service .image-wrapper {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;       /* Ensures the image is cropped */
  border-radius: 8px;
}

.service img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);  /* Centers the image */
}


.service h3 {
  color: #2980b9;
  margin: 1rem 0;
}

/* Testimonials Section */
.testimonials {
  background-color: #f7f7f7;
  padding: 3rem;
}

.testimonial {
  max-width: 600px;
  margin: 1rem auto;
  padding: 1.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Footer Section */
.footer {
  background-color: #34495e;
  color: white;
  padding: 2rem 0;
}

.footer-content {
  display: flex;
  justify-content: space-around;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
}

.footer-nav ul li {
  margin: 0.5rem 0;
}
